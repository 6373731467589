<template>
  <v-container>
    <v-row no-gutters>
      <v-text-field v-model="search" outlined rounded label="Search Notification..."></v-text-field>
      <v-btn @click="statusChange(0)" class="ml-2" rounded height="56" elevation="0" dark>
        <v-icon>mdi-check</v-icon>
        Read All
      </v-btn>
    </v-row>
    <v-card outlined>
      <v-data-table :loading="loading" :search="search" :headers="headers" :item-class="(item) => item.has_seen== 0? 'yellow' :  ''"  :items="courtesyNotes">
        <template v-slot:item.description="{ item }">
          <v-btn outlined rounded @click="descriptionViewDialog = true; selectedDescription = item.description">
            View
          </v-btn>
        </template>

        <template v-slot:item.image="{ item }">
          <v-btn outlined rounded target="_blank" :href="$store.state.s3ResourcesBaseUrl + item.image">
            View
          </v-btn>
        </template>

        <template v-slot:item.ad_url="{ item }">
          <v-btn outlined rounded target="_blank" :href="item.ad_url">
            Visit
          </v-btn>
        </template>

        <template v-slot:item.vending_history.created_at="{ item }">
          {{
            covert_localtime(item.vending_history.created_at)
          }}
        </template>

        <template v-slot:item.vending_history.package.duration_amount="{ item }">
          {{
            item.vending_history.package.duration_amount + " " + item.vending_history.package.duration_unit
          }}
        </template>


        <template v-slot:item.vending_history.payment_type="{ item }">
          {{ item.vending_history.payment_type == '1' ? 'Card' : 'E-Gift' }}
        </template>
        
        <template v-slot:item.vending_history.released_status="{ item }">
          {{ item.vending_history.released_status == '0' ? 'Not Relesed' : 'Released' }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn @click="statusChange(item.id)" :disabled="item.has_seen == 1"  icon>
            <v-icon>mdi mdi-eye-check</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog max-width="768" persistent v-if="addStoryDialog" v-model="addStoryDialog">
      <AddNote :notes_obj='courtesy_Notes' :domain="domain" :edit="isEdit" @close="closeDialogs" />
    </v-dialog>

    <v-dialog max-width="768" persistent v-if="descriptionViewDialog" v-model="descriptionViewDialog">
      <v-card>
        <v-card-title>
          <v-row no-gutters>
            <span class="headline">Description</span>
            <v-spacer />
            <v-btn @click="descriptionViewDialog = false; selectedDescription = ''" icon color="red">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-card-title>
        <v-divider class="mb-4" />
        <v-card-text>
          {{ selectedDescription }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { authClient } from "@/plugins/http";

export default {
  name: "NotificationUserOrder",
  data: () => ({
    loading: true,
    domain: '',
    orderList: [],
    addStoryDialog: false,
    descriptionViewDialog: false,
    optionalViewDialog: false,
    selectedOptional: null,
    selectedDescription: null,
    isEdit: false,
    courtesy_Notes: null,
    courtesyNotes: [],
    headers: [
      { text: "User ID", value: "vending_history.user_id" },
      { text: "Transaction Date & Time", value: "vending_history.created_at" },
      { text: "Name", value: "vending_history.buyer.name" },
      { text: "Email", value: "vending_history.buyer.email" },
      { text: "Phone", value: "vending_history.buyer.phone" },
      { text: "Product Name", value: "vending_history.name" },
      { text: "Product Code", value: "vending_history.product_code" },
      { text: "Quantity", value: "vending_history.quantity" },
      { text: "Amount", value: "vending_history.price" },
      { text: "Tax", value: "vending_history.tax" },
      { text: "Total Amount", value: "vending_history.total_amount" },
      { text: "Payment Type", value: "vending_history.payment_type" },
      { text: "Release Status", value: "vending_history.released_status" },
      { text: "Actions", value: "actions" },
    ],
    search: '',
  }),
  methods: {
    rowClass(item) {
      return item.has_seen == 0 ? "yellow-row" : "";
    },
    statusChange(id) {
      authClient.post('/notifcation-center/actions/seen', {
        'type': 'VENDOR_PAY',
        'id' : id
      }).then((response) => {
        let data = response.data[0]
        if (data.status) {
          this.$toast.success({
            title: 'Ok',
            message: data.message,
          });
          this.getNotes()
        } else {
          this.loading = false;
          this.$toast.error({
            title: 'error',
            message: data.message,
          });
        }
      }).catch((error) => {
        console.log(error);
      });
    },
    getNotes() {
      authClient.post('/notifcation-center/actions/list', {
        'type': 'VENDOR_PAY'
      }).then((response) => {
        let data = response.data
        if (data) {
          this.courtesyNotes = data;
        } else {
          this.loading = false;
          this.$toast.error({
            title: 'error',
            message: data.message,
          });
        }
        this.loading = false;
      }).catch((error) => {
        console.log(error);
        this.loading = false;
      });
    },
    closeDialogs() {
      this.addStoryDialog = false;
      this.getNotes();
    },
    listenNotification(){
      this.$echo.channel('purchases')
      .listen('.purchase.made', (data) => {
        if( data.purchaseData.item == "Vending Order purchase" ){
          this.getNotes();
        }
      })
    }
  },
  mounted() {
    this.getNotes();
    this.listenNotification();
  }
}
</script>

.yellow-row {
  background-color: yellow !important;
}

<style scoped></style>